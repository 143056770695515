import React from "react"
import BasicLayout from "src/components/layout/BasicLayout/BasicLayout"
import SEO from "src/components/seo"
import DevelopmentPage from "src/components/DevelopmentPage/DevelopmentPage"

const PhpDevelopment = () => (
  <BasicLayout stickyBtn={false}>
    <SEO title="Solutions development" />
    <DevelopmentPage
      pageTitle="Solutions Development"
      imagePath="php-development-intro.png"
    />
  </BasicLayout>
)

export default PhpDevelopment
